// @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;500;600;700&display=swap');
$body-bg: #111010;
$body-color: #ecbb52;
$tablet: 767.98px;
$large: 991.98px;

$theme-colors: (
  "site-primary": #111010,
  "site-secondary": #ecbb52,
  "site-border-color": #e3e4e6,
  "site-nav-link": #060a0d,
);

// Bootstrap and its default variables
@import "node_modules/bootstrap/scss/bootstrap";
@font-face {
  font-family: TrajanPro;
  src: url(../fonts/TrajanPro-Regular.otf);
}
@font-face {
  font-family: GillSans;
  src: url(../fonts/GillSans.ttf);
}
.font-family {
  &-1 {
    font-family: TrajanPro;
  }
  &-2 {
    font-family: GillSans;
  }
}
body {
  // font-family: 'Montserrat', sans-serif !important;
  font-weight: 400;
  font-size: 14px;
  h2 {
    text-transform: uppercase;
    font-size: 2rem;
    @media (max-width: 1400px) {
      font-size: 1.9rem;
    }
    @media (max-width: 1200px) {
      font-size: 1.7rem;
    }
    @media (max-width: 800px) {
      font-size: 1.4rem;
    }
    @media (max-width: 400px) {
      font-size: 1.1rem;
    }
  }
  h3 {
    text-transform: uppercase;
    font-size: 1.75rem;
    @media (max-width: 992px) {
      font-size: 1.7rem;
    }
    @media (max-width: 800px) {
      font-size: 1.6rem;
    }
    @media (max-width: 700px) {
      font-size: 1.5rem;
    }
  }
}
.fbtn {
  // background-color: theme-color('site-secondary') ;
  // color: theme-color('site-primary');
  background-color: #ecbb52;
  color: #000;
  border: 0;
  border-radius: 50px;
  font-size: 14px;
  padding: 10px;
  &:hover {
    // padding: 20px;
    background-color: #ecbb52;
    color: #000;
    text-decoration: none;
  }
}
.watchbtn {
  background-color: red;
  color: #fff;
  border: 3px solid red;
  border-radius: 5px;
  padding: 5px 10px;
  &:hover {
    background-color: transparent;
    border: 3px solid red;
    border-radius: 5px;
    color: red;
    text-decoration: none;
  }
}
.bef {
  max-width: 1440px;
  margin: 0 auto;
  .slick-arrow {
    color: theme-color("site-secondary") !important;
    font-size: 32px;
    &::before {
      display: none;
    }
  }
}
.link {
  text-decoration: none;
  color: white;
  &:hover {
    color: #fff;
    text-decoration: none;
  }
}
.close {
  padding: 0px !important;
  border-radius: 5px !important;
  color: white;
  background-color: black !important;
  margin: 0px 0px -1rem auto !important;
}
.imgGlry {
  padding: 10px;
  text-align: center;
}
.imgGlry img {
  border: solid 1px #e9bc66;
}
.bg_image {
  //   background-image: linear-gradient(
  //       180deg,
  //       #2d2d2d 0%,
  //       rgba(45, 45, 45, 0) 100%
  //     ),
  //     url("../img/Triton-Millions_KV_prv2_top_sparkle 4.png");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100% 50%, contain;
  position: relative;
  display: grid;
  grid-template-columns: 2fr 1fr;
  padding: 40px 0;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}
.bg_image1 {
  //   background-image: url("../img/Triton-Millions_KV_prv2_top_sparkle 2.png");
  background-position: bottom;
  background-repeat: repeat-x;
  background-size: "100% 100%";
}
.bg_image2 {
  background-image: url("../img/Triton-Millions_KV_prv2_top_goldplate_players 1.png");
  background-position: bottom;
  background-repeat: repeat-x;
  padding: 40px 0px 0px 0px;
  background-size: cover;
  position: relative;
}
.bg_image3 {
  //   background-image: url("../img/foreground graphic.png");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  bottom: 0px;
  height: 100%;
  width: 100%;
  z-index: 200;
  border-bottom: solid 1px #e9bc66;
}
.pointer {
  cursor: pointer;
}
.participants {
  .slick-arrow {
    color: theme-color("site-secondary") !important;
    font-size: 32px;
    &::before {
      display: none;
    }
    &.slick-prev {
      display: none !important;
    }
  }
  img {
    max-width: 100%;
  }
}
.participants-text {
  display: grid;
  grid-template-columns: 1fr 0.1fr 1fr;
  position: absolute;
  align-items: center;
  left: 50%;
  top: 120px;
  transform: translateX(-50%);
  color: rgba(243, 243, 233, 0.45);
  z-index: 999;
  @media (max-width: 500px) {
    margin-top: -35px;
    padding-bottom: 60px;
    top: 150px;
  }
  .participant-detail {
    // padding: 0px;
    margin-left: 20px;
    margin-right: 20px;
    max-width: 260px;

    @media (max-width: 1050px) {
      //   max-width: 250px;
      padding: 0px 20px;
      margin: 0;
    }
    @media (max-width: 900px) {
      //   max-width: 160px;
      padding: 0px 5px;
      margin: 0;
    }
    @media (max-width: 700px) {
      //   max-width: 120px;
      padding: 0px 10px;
      margin: 0;
    }
    @media (max-width: 400px) {
      max-width: 120px;
      margin: -30px 0px !important;
    }
    h1 {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 35px;
      @media (max-width: 1050px) {
        font-size: 27px;
      }
      @media (max-width: 900px) {
        font-size: 20px;
      }
      @media (max-width: 700px) {
        font-size: 16px;
      }
    }
  }
}
.participant-left {
  @media screen and (max-width: 500px) {
    margin-left: -100px;
    bottom: -50px;
  }
}
.participant-right {
  @media screen and (max-width: 500px) {
    margin-right: -100px;
    bottom: -50px;
  }
}
.smalltext {
  font-size: xx-small;
}
.bg_image4 {
  background-image: url("../img/Triton-Millions_KV_prv2_top_sparkle 3.png");
  background-position: bottom;
  background-repeat: repeat-x;
  background-size: "100% 100%";
}
.reviews {
  max-height: 150px;
  color: white;
  padding-left: 200px;
  margin-left: 200px;
  margin-right: -200px;
  z-index: 200;
  margin-top: 15%;
  overflow: scroll;
  @media (max-width: 1500px) {
    padding-left: 150px;
    // margin-left: 200px;
    // margin-right: -200px;
  }
  @media (max-width: 1200px) {
    padding-left: 60px;
    margin-left: 160px;
    margin-right: -160px;
  }
  @media (max-width: 900px) {
    padding-left: 40px;
    max-height: 110px;
    margin-left: 140px;
    margin-top: 10%;
    margin-right: -140px;
  }
  @media (max-width: 500px) {
    padding-left: 0px;
    padding-top: 40px;
    max-height: 120px;
    margin: auto;
  }
}
.participant-wrapper {
  position: relative;
  overflow: hidden;
  .participant-detail {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    top: 100%;
    left: 0;
    cursor: pointer;
    transition: 0.5s;
  }
  &:hover {
    .participant-detail {
      top: 0;
    }
  }
  img {
    width: 100%;
    @media (max-width: 500px) {
      width: 75%;
    }
  }
}

::-webkit-scrollbar {
  display: none;
}

.navbarbg {
  background-color: #111010f2;
  z-index: 1000;
  border-bottom: 1px solid #e9bc66;
  padding: 0;
}
.navlink {
  color: #ecbb52;
  text-decoration: none;
  cursor: pointer;
  font-size: 18px;

  &:hover {
    // padding: 20px;
    color: #ecbb52;
    font-weight: 600;
    text-decoration: none;
  }
}
.navlink-active {
  color: #ecbb52;
  font-weight: 600;
  text-decoration: none;
}
.charity-logo {
  width: 120px;
}
.charity-slider {
  max-width: 90%;
  margin: 0 auto;
  @media (max-width: 768px) {
    max-width: 90%;
  }
  @media (max-width: 500px) {
    .slick-prev {
      left: 0px;
    }
    .slick-next {
      right: 0px;
    }
  }
}

.episode-slider {
  width: 90%;
  max-width: 1440px;
  margin: 60px auto 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    max-width: 85%;
  }
}

.ep-slider-item {
  text-align: center;
  img {
    width: 360px;
    height: 200px;
  }
  img,
  iframe {
    border: solid 1px #e9bc66;
    margin: 0 auto;
    @media screen and (max-width: 500px) {
      width: 100%;
    }
  }
}

.event-deets {
  padding: 20px;
  margin: 0 auto;
  display: inline-flex;
  align-items: center;
  h2 {
    border-bottom: solid 1px #e9bc66;
    color: #e9bc66;
    display: inline-block;
    padding-bottom: 10px;
    @media screen and (max-width: 500px) {
      font-size: 16px;
    }
  }
  .event-content {
    // border: solid 1px #e9bc66;
    padding: 20px 20px 20px 120px;
    max-width: 85%;
    margin: 0 auto;
    height: max-content;
    max-width: 950px;
    .text-muted {
      padding-top: 10px;
      color: #e9bc66 !important;
    }
    @media (min-width: 1440px) {
      padding: 20px 20px 20px 160px;
    }
    @media screen and (max-width: 768px) {
      padding: 20px;
    }
    @media screen and (max-width: 500px) {
      .text-muted,
      p {
        font-size: 14px;
      }
    }
  }
}
.trophy {
  text-align: center;
  img {
    width: 290px !important;
  }
  @media screen and (max-width: 768px) {
    margin: 0 auto;
    img {
      width: 200px !important;
    }
  }
}
.beneficiary {
  padding-bottom: 20px;
}

.sponsors {
  img {
    width: 100%;
    border: solid 1px #e9bc66;
    @media (max-width: 500px) {
      margin-bottom: 20px;
    }
  }
}
.top-site {
  background: url("../img/Triton-Millions_KV_prv2_top_sparkle_recolored yella.png")
    no-repeat;
  background-position: top;
  background-size: contain;
}
